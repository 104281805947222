.viewpoint-height{
    height: 77vh;
}
.overview-box.actions-amount-box > .overview-box-content{
    cursor: pointer;
}
.overview-box .overview-box-content .overview-box-amounts {
    height: 100px;
    border-bottom: 1px;
    border-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
    .overview-box .overview-box-content .overview-box-amounts .total-amount-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
        .overview-box .overview-box-content .overview-box-amounts .total-amount-box .total-amount-title{
            color:#CBCBCB;
            font-size: 15px;
            margin: 0px 0px 10px 0px;
        }
        .overview-box .overview-box-content .overview-box-amounts .total-amount-box .total-amount-digits{
            display: flex;
        }
            .overview-box .overview-box-content .overview-box-amounts .total-amount-box .total-amount-digits .total-amount-value-integer{
                font-size: 22px;
            }
            .overview-box .overview-box-content .overview-box-amounts .total-amount-box .total-amount-digits .total-amount-value-decimal{
                margin: 2px 0px 0px 0px;
            }

    .overview-box .overview-box-content .overview-box-amounts .total-available-amount-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
    }
        .overview-box .overview-box-content .overview-box-amounts .total-available-amount-box .total-amount-title{
            color:#CBCBCB;
            font-size: 15px;
            margin: 0px 0px 10px 0px;
        }
        .overview-box .overview-box-content .overview-box-amounts .total-available-amount-box .total-amount-digits{
            display: flex;
        }
            .overview-box .overview-box-content .overview-box-amounts .total-available-amount-box .total-amount-digits .total-amount-value-integer{
                font-size: 22px;
            }
            .overview-box .overview-box-content .overview-box-amounts .total-available-amount-box .total-amount-digits .total-amount-value-decimal{
                margin: 2px 0px 0px 0px;
            }

.overview-box .overview-box-content .overview-box-actions {
    height: 20px;
    border-top: 1px;
    margin: 10px 0px 4px 0px;
    border-color: #ffffff;
}

.overview-box-actions{
    position: sticky;
    top: 0;
}

.overview-box.overview-amount-box{
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(219, 219, 219);
}

ul.transaction-list{
    margin: 0px;
}

ul.transaction-list li{
    list-style-type: none;
}

.transaction-view{
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0px;
}
ul.transaction-list li .transaction-view .transaction-description{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
}

    ul.transaction-list li .transaction-view .transaction-description img{
        margin: 10px 10px 10px 0px;
    }

ul.transaction-list li .transaction-view .transaction-amount{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin: 0px 5px 0px 0px;
}
    ul.transaction-list li .transaction-view .transaction-amount .transaction-date{
        color: #999999;
        font-size: 10px;
    }
    ul.transaction-list li .transaction-view .transaction-amount .transaction-number{
        color: #484747;
        font-size: 20px;
    }

.transaction-container{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 70vh;
}

.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
}