.custom-spinner {
	font-size: 4em;
	margin-top: 78px;
	margin-bottom: 20px;
	color: #2CB98D;
}
	.custom-spinner-backdrop {
		position: fixed;
		box-sizing: border-box;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1020;
		background: rgba(0,0,0,0.5) no-repeat center center fixed;
		overflow-y: hidden;
		overflow-x: hidden;
		display: flex;
		justify-content: center;
		outline: 0;
	}

	.custom-spinner-containter {
		position: relative;
		width: auto;
		margin: 30px auto;
		background-color: #fff;
		border: 1px solid rgba(0,0,0,.2);
		border-radius: 6px;
		align-self: center;
	}
	.custom-spinner-content {
		position: relative;
		padding: 15px;
	}
		.custom-spinner-content img{
			height: auto;
			width: 100px;
		}
		.custom-spinner-content p{
			color: #333 !important;
			font-size: 16px;
			margin-top: 15px;
		}
.image-container {
  position: relative;
  display: inline-block;
}

.image-loading {
  filter: grayscale(100%);
  transition: filter 0.5s ease;
}

@keyframes recargarImagen {
  0% {
    filter: grayscale(100%);
  }
  100% {
    filter: grayscale(0%);
  }
}

.image-loading.active {
  animation: recargarImagen 2s ease infinite; /* Cambia el tiempo según tus necesidades */
}

@media(max-width: 767px) {
	.custom-spinner-containter {
		position: relative;
		width: auto;
		margin: 10px;
		background-color: #fff;
		border: 1px solid rgba(0,0,0,.2);
		border-radius: 6px;
	}
}
	.spinner p {
		font-size: 22px;
		color: #2CB98D;
	}