.note-reminder-calendar > .p-datepicker.p-component.p-connected-overlay-enter-done {
    left: 0 !important;
    width: auto !important;
}

.note-reminder-calendar > .p-datepicker {
    width: auto !important;
}

.datepicker-block .p-datepicker-inline {
    display: inline-block;
}
