.custom-modal-header-view {
    position: relative;
    height: 57px;
    padding: 10px;
    background-color: #ffff;
    color: black;
    border-bottom: 1px solid #dbdbdb;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
  }
  .custom-modal-header-title-view {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 800;
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    text-align:center;
  }
  .custom-modal-header-right-items {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    text-align:center;
  }
  .custom-modal-body {
      position: relative;
      padding: 15px 16px 3px 16px;
      height: 82%;
      overflow: hidden;
  }

  @media (max-width: 900px) {
    .custom-modal-body {
      height: 90%;
    }
  }

  @media (min-width: 1800px) {
    .custom-modal-body {
      height: 90%;
    }
  }
  
  .custom-modal-footer {
    position: relative;
    height: 57px;
    padding: 2px 16px;
    background-color: #ffff;
    color: black;
    border-top: 1px solid #dbdbdb;
    vertical-align: middle;
  }
  
  .custom-modal-footer-right-items {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }