.messenger-message-list-container{
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
}

.message-list-container {
  padding: 10px;
  min-height: 70vh;
}

.multiselect-custom-tags-list {
  max-width: 400px;
}
.multiselect-custom-tags-list > .p-multiselect-label-container {
  padding: 0;
}

.multiselect-custom-tags-list > .p-multiselect-panel > .p-multiselect-header  {
  padding: 0.75rem;
}


.multiselect-custom-tags-list .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.75rem;
}

.multiselect-custom-tags-list .p-multiselect-panel {
  min-width: 300px;
}

.multiselect-custom-user-group-list {
  max-width: 400px;
  right: 5em;
}

@media screen and (max-width: 600px) {
  .multiselect-custom-user-group-list {
    top:-6em;
    right: -3em;
  }
}

.multiselect-custom-user-group-list > .p-multiselect-label-container {
  padding: 0;
}

.multiselect-custom-user-group-list > .p-multiselect-panel > .p-multiselect-header  {
  padding: 0.75rem;
}


.multiselect-custom-user-group-list .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.75rem;
}

.multiselect-custom-user-group-list .p-multiselect-panel {
  min-width: 300px;
}

.my-multiselected-item-token {
  background: #455a64;
  color: #fff;
  padding: 2px 4px;
  margin: 0 0.286em 0 0;
  display: inline-block;
  vertical-align: middle;
  height: 1.857em;
  border-radius: 3px;
}


.messenger-toolbar {
  background-color: #FFF;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.16));
}

@media screen and (min-width: 768px) {
  .messenger-toolbar{
    height: 88px;
  }
}

@media screen and (max-width: 600px) {
  .messenger-toolbar{
    padding: 12px 2px;
  }
  .messenger-toolbar .btn-actions.btn-fontawesome{
    margin-right: 0px;
  }
  .messenger-toolbar .toolbar-title{
    margin: 0 !important;
  }
}
@media (max-width: 600px) {
  .messenger-toolbar .conversation-photo {
    width: 40px !important;
    height: 40px !important;
    top: auto !important;
    font-weight: 700;
  }

  .messenger-toolbar .conversation-photo span{
     font-size: 14px;
  }
}

.scroll-botton-button {
  position: absolute;
  bottom: 95px;
  right: 30px;
  z-index: 2;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .scroll-botton-button {
    bottom: 80px;
    right: 20px;
  }
}

.center {
  text-align: center;
}

.btn {
  width:30px;
  height:30px;
  border-radius:100%;
  color:#FFF;
  font-size:28px;
  padding: 6px;
  margin: 2px;
  transform:scale(1);
}

.btnEmoji {
  width:22px;
  height:22px;
  border-radius:100%;
  color:#FFF;
  font-size:28px;
  outline:none;
  padding: 6px;
  margin: 2px;
  transform:scale(1);
  cursor: pointer;
}

.botonF2 {
  width:22px;
  height:22px;
  background:#607D8B;
  transition:0.5s;
}

.botonF3 {
  width:22px;
  height:22px;
  background:#673AB7;
  transition:0.7s;
}

.botonF4 {
  width:22px;
  height:22px;
  background:#009688;
  transition:0.9s;
}

.botonF5{
  width:22px;
  height:22px;
  background:#FF5722;
  transition:0.99s;
}

.botonCancel{
  color:#B81616;
  transition:0.99s;
}

.botonSend{
  color:#14AE24;
  transition:0.99s;
}

.botonTransp{
  width:22px;
  height:22px;
  color:#19418BE5;
  background:transparent;
  transition:0.99s;
}

.botonWhite{
  width:22px;
  height:22px;
  color:#19418BE5;
  background:transparent;
  transition:0.99s;
}

.dropZone{
  position: relative;
  border: 15px dotted #bebebe;
  background-color: #FFF;
}

.dropZone h3 {
  color: #bebebe;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.context-items {
  max-width: 97%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -5%;

}

.compose-element {
  display: flex;
  position: relative;
  bottom: 0;
}

.customTagsList{
  position: absolute;
  top: 5em;
  right: 15em;
}

@media screen and (max-width: 600px) {
  .customTagsList{
    position: absolute;
    top: 5em;
    right: 5em;
  }
}

.userList {
  position: absolute;
  top: 5em;
  right: 15em;
}

.userGroupList {
  position: absolute;
  top: 5em;
  right: 6em;
  width: auto;
}

@media screen and (max-width: 600px) {
  .userGroupList {
    position: absolute;
    top: 10em;
    right: 6em;
    width: auto;
  }
  .btn-caret-down{
    padding: 0px !important;
  }
  .btn-back{
    padding: 0px 5px 0px 0px!important;
  }
}

.btn-forward {
  background:transparent;
  transition:0.99s;
  border-radius:100%;
  color:#009605;
  font-size:28px;
  padding: 6px;
  margin: 2px;
}

.btn-forward-disabled {
  color:#607D8B;
  cursor: not-allowed;
}

.select-user-group {
  color: white;
  border: 2px dotted #19418BE5;
  font-weight: bolder;
  padding: 5px 0;
  background: #19418BE5;
  display:flex;
}

.footer-scheduled-message {
  text-align: center;
}

.footer-scheduled-message button{
  margin-bottom: 1rem;
}

.btn-cancel-message-scheduled {
  background: red;
}
.btn-send-message-scheduled {
  cursor: pointer;
}

.btn-send-message-scheduled-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.message-scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@media (min-height: 700px) {
  .message-scrollable {
    height: 80vh;
  }
}

@media (max-height: 700px) {
  .message-scrollable {
    height: 74vh;
  }
}