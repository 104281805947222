.conversation-list {
  position: relative;
  display: flex;
  height: auto;
  flex-direction: column;
}

.conversation-list > .toolbar-title {
  align-self: center;
}

.conversation-list  .toolbar-custom .right-items svg{
  width: 2em !important;
  height: 2em !important;
  padding: 6px;
}

.close-dialog {
  margin-top: 0 !important;
}

.close-dialog > .p-dialog-titlebar-close-icon {
  font-weight: bold;
  color: #a7a7a7 !important;
  font-size: x-large;
}

.toolbar-action-buttons {
  display: inline-flex;
  padding: 0;
}

.close-custom-tag {
  width: 2em !important;
  height: 2em !important;
}

.custom-tag-get-back {
  color: #a7a7a7;
  margin-right: 0.5em;
  cursor: pointer;
}

.p-dialog-titlebar {
  border-bottom: 0 none !important;
  background: #ffffff !important;
  color: #495057 !important;
  padding: 1rem !important;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
  border-bottom: 1px solid #bdbdbd !important;
}

.p-dialog-header {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 1em;
}

.mt-1 {
  margin-top: 1em;
}

.conversation-list .p-contextmenu {
  left: 5px !important;
  top: 70px !important;
}

@media (max-width: 600px) {
  .conversation-list .p-contextmenu {
    left: 20vh !important;
    left: 20dvh !important;
  }
}

.wrapper-cell-loading {
  padding: 5px;
  display: flex;
  margin-bottom: 15px;
}

.fa-layers-counter {
  font-size: xxx-large;
  bottom: var(--fa-bottom, 0.25em);
  right: var(--fa-right, -0.5em)
}

/* Style the tab */
.toolbar-custom {
  position: static;
  height: 44px !important;
  padding: 15px 7px 1px;
}

@media (max-width: 600px) {
  .toolbar.toolbar-custom .right-items {
    display: flex;
    align-items: center;
  }
}

/* Style the buttons that are used to open the tab content */
.toolbar-custom .left-items {
  position: relative;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  //padding: 14px 16px;
  //transition: 0.3s;
  //width: 50%;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  //font-size: 15px;
}

.toolbar-custom .left-items .toolbar-pip-logo img {
  margin-top: 8px;
}

/* Change background color of buttons on hover */
.toolbar-custom button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.toolbar-custom button.active {
  background-color: #ccc;
}

.toolbar-custom .tablinks {
  padding: 1px;
}

.init-conversation {
  display: inline-flex;
}

.init-conversation-main {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #f1efee;
  border-right: none;
}

.init-conversation-icon {
  position: relative;
  width: 1.5em;
  height: 1.5em;
  top: 5px;
  right: 14px;
}

.init-conversation-icon-true {
  cursor: pointer;
}

.init-conversation-icon-false {
  opacity: 0.5;
  cursor: not-allowed;
}

.search-filter {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  padding: 1px 1px 5px;
  width: 100%;
}

.user-tag-filter {
  position: relative;
  padding: 1px 10px 1px;
}

.user-tag-filter .custom-tags {
  margin: 5px;
 }

 .user-tag-filter > *:not(:empty) {
  margin-bottom: 10em;
}

@media (min-width: 750px) {
  .menu-filter {
    position: absolute;
    margin-top: 8.5em;
    margin-left: -10.5em;
    top: 100px;
    z-index: 3;
  }
}

@media (max-width: 750px) {
  .menu-filter {
    position: absolute;
    margin-top: 10.5em;
    margin-left: -10.5em;
    top: 80px;
    z-index: 3;
  }
}

@media (min-width: 750px) {
  .search-filter .list-drop {
    margin-left: 15px;
    margin-right: 16px;
    z-index: 1;
    flex: auto;
    max-width: 362px;
    display: flex;
    flex-direction: column;
    padding: 15px 5px 15px 5px;
  }
  .search-filter .list-drop .p-dropdown{
    border-radius: 10px;
  }
}

@media (max-width: 750px) {
  .search-filter .list-drop {
    margin-left: 15px;
    margin-right: 16px;
    z-index: 1;
    flex: auto;
    max-width: 362px;
    display: flex;
    flex-direction: column;
    padding: 10px 3px 10px 3px;
    max-width: 80%;
  }
  .search-filter .list-drop .p-dropdown{
      border-radius: 10px;
  }
}

.conversation-contact-phone-invalid {
  width: 100%;
  color: red;
  text-align: center;
}

.audio-player-visible, .audio-player-hidden {
  max-width: 444px;
  bottom: 13px;
  position: fixed;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  z-index: 100;
  display: flex;
}

.audio-player-hidden {
  display: none;
}

.audio-info-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (max-width: 1000px) {
  .audio-player-visible, .audio-player-hidden {
    max-width: 386px;
  }
}

.p-dialog-header-close-audio:hover {
  background-color: rgba(0, 0, 0, 0.1);
}