.custom-modal-header {
    position: relative;
    background-color: #ffff;
    color: black;
    display: inline-block;
    white-space: nowrap;
    vertical-align: middle;
  }
  .custom-modal-header-title {
    position: relative;
    font-size: 16px;
    font-weight: 800;
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    text-align:center;
  }
  .custom-modal-header-right-items {
    position: relative;
    top: 2.5vh;
    left: 55vh;
    transform: translateY(-50%);
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
    text-align:center;
  }
  .custom-modal-body {
      position: relative;
      padding: 15px 16px 3px 16px;
      overflow: hidden;
  }
  .custom-modal-footer {
    position: relative;
    height: 57px;
    padding: 2px 16px;
    background-color: #ffff;
    color: black;
    border-top: 1px solid #dbdbdb;
    vertical-align: middle;
  }
  
  .custom-modal-footer-right-items {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }
