.invalid-emails-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.invalid-email {
    background-color: #ffcccc;
    color: #cc0000;
    margin-right: 10px;
    padding: 5px;
    border-radius: 5px;
    font-size: smaller;
}
