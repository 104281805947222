.withdrawal-amount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
}
.input-money{
    display: inherit;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px 10px 0px;
    text-align: center;
}
.withdrawal-amount .withdrawal-digits{
    font-size: 20px;
    color: #888787;
}
.withdrawal-amount .withdrawal-description{
    font-size: 17px;
    color: #3E3E3E;
}

.withdrawal-footer.invalid {
    color: #ff0000;
}

.withdrawal-footer {
    margin-top: .5em;
    text-align: center;
    color: #998;
    font-size: .875em;
}


.input-money .withdrawal-digits{
    font-size: 20px;
    color: #888787;
}
.input-money .withdrawal-description{
    font-size: 17px;
    color: #3E3E3E;
    padding-bottom: 20px;
}

.withdrawal-amount.total-amount{
    border-top: ridge;
}

.withdrawal-actions{
    display: flex;
    justify-content: center;
    align-items: center;
}
button.p-button.withdrawal-button{
    width: 234px;
    font-size: 21px;
    background-color: #757575;
    color: #ffffff;
}