.p-chips.p-component.show-tag > ul {
    opacity: 1;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-token{
    padding: 9px;
}

.client-tags-multiview .p-chips-multiple-container {
    background-color: #FFFFFF;
}