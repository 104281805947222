.p-chips-token .p-chips-token-icon {
    display: none;
}

body .p-chips > ul.p-inputtext.p-disabled {
    /*border-bottom: none;*/
    background-image: none;
}

.truncate-word {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.column-filter {
    width: 100%;
    padding: 0.5rem;
}

.column-filter.p-dropdown.column-filter {
    padding: 0;
}
.column-filter.p-dropdown.column-filter > .p-dropdown-label{
    padding: 0.5rem
}

.column-filter.p-multiselect {
    padding: 0;
}

.column-filter.p-multiselect > .p-multiselect-label-container > .p-multiselect-label {
    padding: 0.5rem
}

.p-datatable-thead > tr > td .column-title {
    padding: .4rem;
    min-width: 30%;
    display: inline-block;
    margin: -.4rem 1rem -.4rem -.4rem;
    font-weight: bold;
}

.p-multiselect-filter {
    padding: 0.5rem
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.p-chips-multiple-container {
    padding: 0.5rem !important;
}

.p-chips-token.p-highlight {
    padding: 0.5rem;
}

.p-datatable .p-datatable-footer {
    text-align: center;
}

.btn-actions {
    min-width: auto;
    border-radius: 100%;
    justify-content: center;
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    position: relative;
    padding: 6px;
}

.actions {
    display: flex;
}

.show-chips > .p-chips-multiple-container {
    display: inline-block;
    width: 100%;
    border: none;
    opacity: 1;
}

.checkbox-chips-green {
    color: green;
}

.checkbox-chips-red {
    color: red;
}

.p-datatable .p-datatable-tbody > tr.p-highlight > td > .show-chips > .p-chips-multiple-container {
    background: rgba(96, 125, 139, 0.12);
    color: red;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover > td > .show-chips > .p-chips-multiple-container{
    background: rgba(96, 125, 139, 0.12);
    color: rgba(0, 0, 0, 0.87);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
    color: #607D8B;
}

.p-datatable .p-datatable-tbody > tr > td > .show-chips > .p-chips-multiple-container {
    background: #FFFFFF;
    color: #607D8B;
}

.p-datatable .p-datatable-tbody > tr.p-highlight > .show-chips > .p-chips-multiple-container {
    background: red;
    color: #607D8B;
}

.datatable-filter-mobile .p-paginator .p-paginator-current {
    margin-left: auto;
}

.datatable-filter-mobile .p-progressbar {
    height: .5rem;
    background-color: #D8DADC;
}

.datatable-filter-mobile .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
}

.datatable-filter-mobile .table-header {
    display: flex;
    justify-content: space-between;
}

.datatable-filter-mobile .p-datepicker {
    min-width: 25rem;
}

.datatable-filter-mobile .p-datepicker td {
    font-weight: 400;
}

.datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
}

.datatable-filter-mobile .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
}

.datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
}

.datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
}

.datatable-filter-mobile .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

.datatable-filter-mobile .p-datatable-customers .p-datatable-tbody > tr > td .column-title {
    display: none;
}

@media screen and (max-width: 750px) {
    .datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
    .datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
        border-bottom: 1px solid;
    }

    .datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100% !important;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4rem 1rem -.4rem -.4rem;
        font-weight: bold;
    }

    .datatable-filter-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
        margin-top: .5rem;
    }
}

.btn-alert {
    width: auto !important;
}
