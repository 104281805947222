.reactions {
    position: absolute;
    height: 24px;
    align-self: flex-end;
}

.message-reactions {
    max-width: 125px;
    position: relative;
    top: 60%;
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    padding-top: 4px;
    flex-direction: row;
    height: 24px;
    background-color: #fff;
}

.reactions-main {
    margin: 6px 16px 0;
    background-color: #fff;
    border: 0;
}


.reaction-detail-label {
    border-top: none;
    padding-right: 15px;
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    min-width: 0;
}

.reaction-detail-label-sender {
    text-align: left;
    display: flex;
    align-items: center;
    line-height: normal;

}

.reaction-detail-label-remove {
    color: #667781;
    font-size: 12px;
    cursor: pointer;
}

.reaction-detail-emoji {
    font-size: 25px;
}