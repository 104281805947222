.overlay-button{
    padding: 5px;
}

.paperclip-font-size {
    font-size: 1.5rem;
    font-weight: bold;
}
#popup_menu_attachment {
    top: -100px !important;
    left: auto !important;
}