.compose {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  border-top: 1px solid #eeeef1;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

@media screen and (max-width: 768px) {
  .compose {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
    }
}
@supports (backdrop-filter: blur(20px)) {
  .compose {
    border: none;
  }
}

.compose-input {
  padding: 10px 10px 8px 10px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.60);
  font-size: 14px;
  font-weight: 700;
  width: 98%;
  color: rgba(0, 0, 0, 0.36);
}

.compose-input.with-text{
  color: black;
  font-weight: 300;
}

.compose-input:disabled {
  background: #f4f4f8;
  border-color: #ccc !important;
}

.compose-input::placeholder {
  opacity: 0.5;
}

.compose textarea.compose-input {
  width: 97%;
  border: solid 3px #dcdddc;
  border-radius: 10px;
  padding: 10px;
  min-width: 97% !important;
  max-width: 97% !important;
  margin-left: 9px;
}

@media screen and (max-width: 768px) {
  .compose textarea.compose-input {
    margin-left: 7px;
  }
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}

.compose .chat-loading {
  width: 97%;
  background-color: #eaeaec;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

.note {
  background-color: #f5b961;
}

.reminder{
  background-color: #AE90DD;
}

.message-preview-context.context-compose {
  max-width: 97%;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: -5.25em;
  position: absolute;
  display: inline-flex;
  background-color: #fff;
}

.forward-items-selection.context-compose {
  max-width: 7%;
  position: absolute;
  display: inline-flex;
}

.chatbox {
  width: 100%;
  max-width: 100%;
}
