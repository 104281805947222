.audio-record {
    display: inline-flex;
}

.audio-record > .p-button {
    margin: 0 0.5rem 0 0.5rem;
}

.audio-timer{
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0 0.5rem;
}