audio {
    color: #000;
}

.message-preview-context-out {
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: #f5f5f5;
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.message-preview-context-in {
    position: relative;
    display: flex;
    overflow: hidden;
    background-color: #a1f1e0e5;
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}

.message-context-line {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    background-color: #06cf9c!important;
    flex: none;
    width: 4px;
    margin-right: 7px;
}
.message-context {
    display: flex;
    flex-grow: 1;
    align-items: center;
    min-height: 42px;
    max-height: 82px;
    overflow: hidden;

}

.message-context-body {
    flex-grow: 1;
    overflow: hidden;
    min-width: 100px;
}

.message-context-body-title {
    display: inline-flex;
    max-width: 100%;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    color: #06cf9c !important;
}

.message-context-body-text {
    display: -webkit-box;
    max-height: 60px;
    overflow: hidden;
    font-size: 13px;
    line-height: 20px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: pre-wrap;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.message-body {
    position: unset;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    margin-top: 10px;
    margin-bottom: 5px;
}

.message-body a {
    color: #0D47A1;
    text-decoration: underline;
}

.message-context-file {
    flex: 0 0 auto;
    overflow: hidden;
}

.message-context-file-body {
    background-position: 50%;
    background-size: cover;
    max-width: 80px;
    max-height: 80px;
}

.context-file {
    max-width: 80px;
}

.message-forwarded {
    color: #8696a0;
    display: inline-block;
    vertical-align: top;
    margin-top: 0.25em;
}
.message-forwarded-icon {
    height: 16px;
    width: 16px;
}
.message-forwarded-text {
    margin-right: 3px;
    margin-left: 3px;
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
    top: -2px;
    position: relative;
    color: #3a444b;
    font-size: 1rem;
}
.message-media {
    margin-top: 20px;
    display: block;
    width: 100%;
    max-height: 340px;
    object-fit: cover;
    object-position: center;
}
.message-referrals {
    display: grid;
    grid-template-rows: 35px 1fr;
}
.message-referrals span{
    position: relative;
    margin-right: 3px;
    margin-left: 3px;
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic;
    top: -2px;
    position: relative;
    color: #3a444b;
    font-size: 1rem;
}
.message-referrals-bubble {
    margin: 1px 0px;
    background: #eeeeee;
    padding: 10px 20px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.message-file-bubble{
    max-width: 380px;
}
.message-file-bubble-out {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    background: #f5f5f5;
    padding: 8px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.message-file-bubble-out span{
    margin-right: auto;
    position: initial;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.message-file-bubble-in {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    background: #a1f1e0e5;
    padding: 8px 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.message-file-bubble-in span{
    margin-right: auto;
    position: initial;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.message-image-video-bubble {
    margin: 5px 0px;
    width: 280px;
}

.message-image-video-bubble img{
    cursor: pointer;
}

.message-image-video-bubble video{
    width: 280px;
}

.message-caption {
    margin-top: 0.5em;
    position: initial;
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.message-preview-options {
    opacity: 1;
    right: 5px;
    cursor: pointer;
    top: 3px;
    z-index: 1;
    max-width: 60%;
    height: 40px;
    overflow: hidden;
    pointer-events: none;
}

.message-preview-icon {
    transform: translate3d(-5px, 5px, 0px);
    position: absolute;
    width: 20px;
    height: 20px;
    pointer-events: auto;
    margin-top: 0.25em;
    padding-right: 30px;
    right: -18px;
}

audio{
    max-height: 100%;
    max-width: 100%;
    margin: auto;
    object-fit: contain;
}

.conversation-button-link {
    color: #027eb5;
}

#popup-menu-contact {
  top: auto !important;
  left: auto !important;
  right: auto !important;
  width: auto !important;
}

@media (max-width: 900px) {
    .message-file-bubble{
        justify-content: center;
    }
    .message-image-video-bubble {
        width: 210px;
    }
    .message-image-video-bubble video{
        width: 210px;
    }
}

.separate-message-interactive {
    border-top: 2px solid #53877ce5;
    width: 280px;
    display: block;
}

.message-interactive-option {
    display: flex;
    flex-grow:1;
    font-size: 17px;
    font-weight: 400;
    line-height: 21px;
    overflow-wrap: break-word;
    margin-top: 0.5em;
}
