
.interest-box{
    padding-left: inherit;
}

.amount-box{
    padding-right: inherit;
}

.overview-box-description{
    min-height: 50px;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 25px;
}

.overview-box-count{
    font-family: "Roboto";
    font-weight: bold;
    font-size: 50px;
}

.dashboard-box{
    padding: 50px;
}

.overview-box.overview-box-4.overview-box-red .overview-box-content{
    background-color: #c64848;
}

.overview-box.overview-box-4.overview-box-yellow .overview-box-content{
    background-color: #eaac40;
}

.overview-box.overview-box-4.overview-box-grey .overview-box-content{
    background-color: #82797d;
}

.parent-box{
    background-color: #d7d5d6;
}

.amount-description-title{
    height: 90px;
}

.dashboard .amount-box .overview-box .overview-box-content .overview-box-count{
    font-size: 40px;
}
.dashboard .interest-box .overview-box .overview-box-content .overview-box-count{
    font-size: 25px;
}

@media screen and (max-width: 576px){
    .dashboard-box{
        padding: inherit;
    }
    .dashboard .amount-box .overview-box .overview-box-content .overview-box-count{
        font-size: 20px;
    }
    .dashboard .overview-box .overview-box-content .overview-box-title.overview-box-description{
        min-height: 50px;
        font-family: "Roboto";
        font-weight: bold;
        font-size: 15px;
    }
}