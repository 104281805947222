.search-list-container{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 200px;
    min-width: 185px;
    position: absolute;
    z-index: 1;
    background-color: #ececec;
}
.search-item-view{
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 5px 0px;
}

ul.search-list{
    margin: 0px;
}

ul.search-list li{
    list-style-type: none;
}

.p-inputtext.search-input{
    position: sticky;
    top: 0;
    background-color: #ffffff;
}