.custom-backdrop {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1020;
    background: rgba(0,0,0,0.5) no-repeat center center fixed;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    outline: 0;
}

.custom-backdrop b {
    color: #19418BE5;
}

.custom-without-backdrop b{
    color: #19418BE5;
}

.custom-without-backdrop {
    position: fixed;
    box-sizing: border-box;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    outline: 0;
}

.custom-backdrop .p-overlaypanel-content {
    padding-top: 5%;
}

.custom-backdrop .p-overlaypanel-content p {
    font-size: 18px;
}
.custom-without-backdrop .p-overlaypanel-content p {
    font-size: 18px;
}

.custom-backdrop .p-overlaypanel {
    width: auto;
    position: absolute;
    transform: translate(4%, -10%);
}

.custom-without-backdrop .p-overlaypanel-content {
    padding-top: 5%;
}

.custom-without-backdrop .p-overlaypanel {
    width: auto;
    position: absolute;
    transform: translate(4%, -10%);
}

@media screen and (max-width: 750px) {
    .custom-backdrop .p-overlaypanel-content {
        padding: auto;
    }
 
    .custom-backdrop .p-overlaypanel {
        padding: auto;
    }
    
    .custom-without-backdrop .p-overlaypanel-content {
        padding: auto;
    }
 
    .custom-without-backdrop .p-overlaypanel {
        padding: auto;
    }
}