.item-list{
	padding: 16px 24px;
	border-top: 1px solid #dbdbdb;
}

.ul-list{
	list-style-type: none;
	padding: 0px 0 32px 0;
}

.icon-list{
	font-size: 24px;
	margin-right: 8px;
	vertical-align: middle;
}

.right-item-list{
	float: right;
}