.message .chat .p-panel {
    border: 0 none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.14);
}
.message .chat .p-panel-content {
    padding: 0 !important;
}
.message .chat .p-tabview {
    border-color: transparent;
}
.message .chat .p-tabview .p-tabview-panel {
    border: 0 none;
}
.message .chat .chat-messages {
    padding: 12px;
    margin: 0;
    list-style-type: none;
    border: 0 none;
}

.message .chat .scroller-messages {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 700px;
    min-height: 100px;
}

.message .chat .chat-messages li {
    padding: 6px 0;
}
.message .chat .chat-messages li:before, .message .chat .chat-messages li:after {
    content: "";
    display: table;
}
.message .chat .chat-messages li:after {
    clear: both;
}
.message .chat .chat-messages li img {
    width: 36px;
    float: left;
}
.message .chat .chat-messages li span {
    padding: 6px 12px;
    float: left;
    display: inline-block;
    margin: 4px 0;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.message .chat .chat-messages li div {
    padding: 6px 12px;

    float: right;
    display: inline-block;

    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-size: 10px;
}

.message .chat .chat-messages li.message-from img, .message .chat .chat-messages li.message-from span {
    float: right;
}

.message .chat .chat-messages li.message-from div {
    float: right;
    margin-left: 85%;
}

.message .chat .chat-messages li.message-from img {
    margin-right: 8px;
}
.message .chat .chat-messages li.message-from span {
    background-color: #e8eaf6;
}


.message .chat .chat-messages li.message-own img, .message .chat .chat-messages li.message-own span {
    float: left;
}
.message .chat .chat-messages li.message-own img {
    margin-left: 8px;
}
.message .chat .chat-messages li.message-own span {
    background: #c8e6c9;
    color: #000000;
}

.message .chat .chat-messages li.message-own div {
    float: left;
    margin-right: 85%;
}

.message .chat .new-message {
    height: 40px;
    border-top: 1px solid #dce2e7;
    color: #afafc0;
}
.message .chat .new-message .message-attachment {
    display: inline-block;
    border-right: 1px solid #dce2e7;
    width: 40px;
    line-height: 40px;
    height: 100%;
    text-align: center;
}
.message .chat .new-message .message-attachment i {
    line-height: inherit;
    font-size: 24px;
}
.message .chat .new-message .message-input {
    position: relative;
    top: -6px;
    width: calc(100% - 100px);
    display: inline-block;
}
.message .chat .new-message .message-input input {
    border: 0 none;
    font-size: 12px;
    width: 100%;
    background-color: transparent;
    outline: 0 none;
    color: #757575;
}

.message .chat .new-message .message-attachment-button {
    display: inline-block;
    width: 40px;
    height: 100%;
    text-align: right;
}

.message .chat .new-message .message-attachment-button .message-button {
    width: 2em;
    height: 2em;
}

