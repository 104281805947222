@media (max-width: 1024px) {
    .layout-wrapper .layout-main .layout-topbar .layout-topbar-menu-wrapper .topbar-menu.topbar-menu-active > li > button > a {
        display: block;
        color: #212121;
    }
}

@media (max-width: 1024px) {
    .layout-wrapper .layout-main .layout-topbar > img {
        max-width: 65%;
        max-height: 48px;
        height: auto!important;
        padding-top: 10px;
    }
}

@media (min-width: 750px) {
    .layout-wrapper .layout-main .layout-topbar {
        top: 0;
        left: 0;
        right: 0;
        height: 58px;
        z-index: 1000 !important;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .layout-wrapper .layout-main .layout-topbar{
        height: 58px;
    }
    .layout-wrapper .layout-main .layout-topbar.l-topbar-messenger-hidden{
        display: none !important;
    }
    .layout-wrapper .layout-main .layout-topbar > img {
        padding-top: 0;
    }
}
