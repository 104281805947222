body .p-dialog .p-dialog-footer .p-button.p-button-dialog-success {
    background-color: #4CAF50;
    color: #ffffff;
}

body .p-dialog .p-dialog-footer .p-button.p-button-dialog-danger {
    background-color: #F44336;
    color: #ffffff;
}

body .p-dialog .p-dialog-footer .p-button .pi {
    color: #ffffff;
}

.input-hidden {
    padding: 0 !important;
}