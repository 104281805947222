.landing-body #video > div {
    text-align: left;
}

.landing-body p {
    line-height: inherit;
}

.p-card-content > div {
    line-height: 7px;
}

.logo-client {
    width: 260px;
    height: 60px;
}
.landing-body #header > div {
    height: auto;
}
.landing-body #header > div > img {
    max-width: 30%;
}

@media (max-width: 768px) {
    .landing-body #video > div {
        text-align: center;
    }
}