.dropbtn {
    position: relative;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    right: 10px;
    top: 2px;
  }

@media (max-width: 900px) {
  .dropbtn {
    padding: 0px;
    right: auto;
    top: auto;
    font-size: 14px;
  }
}

  .dropbtn-red {
    background-color: #ff2200e3;
    color: white;
    padding: 5px 5px 5px 5px;
    top: '20px';
    font-size: 16px;
    border-radius: 10px;
  }

  .dropdown-red{
    position: relative;
    top: 18px;
    left: 10px;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    /*min-width: 160px;*/
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    bottom: -18px;
    left: 11px;
  }
  
  /* Links inside the dropdown */
  .dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content div:hover {background-color: #c1c1c1
  }
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  