.group-tag{
    background-color: #00bcd4;
    border-radius: 9px;
    padding: 2px 8px;
    color: #ffffff;
    font-size: 14px;
}

.red-tag{
    background-color: #ff2200e3;
    border-radius: 9px;
    padding: 2px 8px;
    color: #ffffff;
    font-size: 14px;
}

.info-contact-flex{
    display: flex;
    padding: 0px 0px 5px 10px;
}

.info-avatar{
    position: relative;
    top: 5px;
}

.info-flex{
    display: flex;
}

.info-padding{
    padding: 20px 10px 10px 5px
}