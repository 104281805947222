.prontocobro-tag{
    border-radius: 140px;
    padding: 2px 8px;
    color: #ffffff;
    font-size: 14px;
    margin-right: 0.25em;
    margin-left: 0;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    max-width: fit-content;
}