ul {
	padding-inline-start: 0px;
}

.my-multiselected-item-token {
	background: #455a64;
	color: #fff;
	padding: 2px 4px;
	margin: 0 0.286em 0 0;
	display: inline-block;
	vertical-align: middle;
	height: 1.857em;
	border-radius: 3px;
}

.multiselect-filter-datatable > .p-multiselect-panel {
	min-width: 200px
}
