.p-datascroller-header {
    background-color: #ffffff;
    border-bottom: 1px solid #e4e4e4;
    display: flex;
}

.checkbox-tag {
    background-color: #607D8B;
}

.checkbox-tag .p-checkbox-box {
    height: 100% !important;
    border-radius: 12px !important;
    border-color: #607D8B;
    padding: 11px;
}
.checkbox-tag .p-checkbox-box.p-highlight {
    padding: 7px !important;
}

.checkbox-tag .p-checkbox-box .p-focus .p-highlight {
    border-color: #607D8B;
}

.checkbox-tag .p-checkbox-box:not(.p-disabled).p-focus {
    border-color: #607D8B !important;
}

.datascroller-tags .tag-name {
display: inline-block;
font-size: 1rem;
font-weight: 700;
background-color: #00bcd4;
border-radius: 9px;
padding: 2px 8px;
margin-top: 0.5em;
color: #ffffff;
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
max-width: 100%;
}

.datascroller-tags .tag-action {
text-align: end;
}

.hr-color {
border-top: 1px solid #bdbdbd;
}

.datascroller-tags > .p-datascroller .p-datascroller-content{
    border: none;
}

.selected-tags-buttons {
text-align: end;
}

.datascroller-tags .p-datascroller-list > li {
    padding-bottom: 7px;
}

.custom-tag-input-btn-disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

@media screen and (max-width: 1280px) {
    .tag-action .checkbox-tag {
        width: 2em !important;
        height: 2em !important;
    }
    .tag-action .btn-action {
        width: 2em !important;
        height: 2em !important;
    }

    .checkbox-tag .p-checkbox-box.p-component.p-highlight .p-checkbox-icon {
        font-size: 1.7em !important;
    }
    .checkbox-tag .p-checkbox-box {
        border-radius: 9px !important;
    }

    .datascroller-tags > .p-datascroller .p-datascroller-content{
        max-height: 250px !important;
    }
}
