.center {
    text-align: center;
}

.photo {
    text-align: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    align-self: flex-start;
}

.qr-text {
  position: relative;
  left: 3%;
  margin-top: 1%;
  justify-content: center;
  align-items: center;
}

.qr {
  display: flex;
}

.qr-warning {
  position: relative;
  left: 3%;
  margin-top: 1%;
}

.qr-text h4 {
  line-height: 10pt;
  font-size: 18px;
}

.qr-imgen {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.overlay-button-reset {
  position: absolute;
  padding: 10px 10px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 2;
}

.button-logout {
  font-size: 1.5rem;
}

.img-grayscale {
  position: relative;
  filter: grayscale(100%);
}

.img-grayscale-show {
  position: relative;;
  filter: blur(1.5px);
}

.datatable-filter-wa-mobile .p-paginator .p-paginator-current {
  margin-left: auto;
}

.datatable-filter-wa-mobile .p-progressbar {
  height: .5rem;
  background-color: #D8DADC;
}

.datatable-filter-wa-mobile .p-progressbar .p-progressbar-value {
  background-color: #607D8B;
}

.datatable-filter-wa-mobile .table-header {
  display: flex;
  justify-content: space-between;
}

.datatable-filter-wa-mobile .p-datepicker {
  min-width: 25rem;
}

.datatable-filter-wa-mobile .p-datepicker td {
  font-weight: 400;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-header {
  padding: 1rem;
  text-align: left;
  font-size: 1.5rem;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-paginator {
  padding: 1rem;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
  text-align: left;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
  cursor: auto;
}

.datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
  text-transform: uppercase;
}

.datatable-filter-wa-mobile .p-datatable-customers .p-datatable-tbody > tr > td .column-title {
  display: none;
}

@media screen and (max-width: 750px) {
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-thead > tr > th,
  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
      display: none !important;
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
      border-bottom: 1px solid var(--layer-2);
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
      text-align: center;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .column-title {
      padding: .4rem;
      min-width: 30%;
      display: inline-block;
      margin: -.4rem 1rem -.4rem -.4rem;
      font-weight: bold;
  }

  .datatable-filter-wa-mobile .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
      margin-top: .5rem;
  }
}

.processing-popup-button{
  width: auto;
}