.p-fluid .p-button.p-contact-button {
    width: initial;
}

.p-tabview-panels.contacts{
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
}

ul.ul-list > li.item-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0px;
}
.p-tabview-panels.contacts > ul.ul-list > li.item-list > .contact-value{
    margin: 0px 10px 0px 0px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
