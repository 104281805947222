.first-row-client{
	height: 500px
}

.second-row-client{
	height: 1070px
}

.due-loan{
	color: red;
}

.operation-select{
	display: flex;
	align-items: center;
	margin: 0px 0px 20px 0px;
}
.operation-select > h1{
	margin: 0px 30px 0px 0px;
}

.operation-select > div{
	margin: 16px 0px 0px 0px;
}

@media screen and (max-width: 576px){
	.first-row-client{
		height: inherit;
	}

	.second-row-client{
		height: inherit;
	}
	.second-row-client.events{
		height: 500px;
	}
}

.separator {
	border-top: 1px solid #bdbdbd;
	list-style-type: none;
}