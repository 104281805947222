.file-right {
    text-align: right;
}

.file-card{
    background-color: #f8f8f8;
    grid-row-start: 1;
    grid-row-end: span 2;
}

.datatable-custom{
    position: relative;
    padding: 25px 70px;
}

@media(max-height: 750px) {
	.file-card{
        position: relative;
        background-color: transparent;
  }
}

.preview-file-message {
    position: relative;
}

@media(max-height: 700px) {
	.preview-file-message{
        min-height: 70vh;
  }
}

@media(min-height: 700px) {
	.preview-file-message{
        min-height: 70vh;
  }
}