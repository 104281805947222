.conversation-info-forward {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
}

.conversation-photo-forward {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    align-self: flex-start;
}

.conversation-title-forward {
    font-size: 15px;
    font-weight: bold;
    text-transform: capitalize;
    margin-top: 0.7em !important;
    margin-left: 3em;
}