.custom-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .custom-modal-content {
    position: absolute;
    transform: translate(-50%,-50%);
    top: 15%;
    left: 50%;
    background-color: #fefefe;
    margin: 15% auto;
    border: 1px solid #888;
    width: 60%;
    height: 30vw;
  }

  @media (max-width: 1050px) {
    .custom-modal-content {
      width: 90%;
      height: 90%;
      top: 35%;
    }
  }

  @media (max-width: 500px) {
    .custom-modal-content {
      top: 40%;
    }
  }

  .custom-modal-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1%;
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }

  .custom-modal-back {
    color: #aaa;
    float: left;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
  }