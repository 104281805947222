.tab {
  display: flex;
  margin: 0 1px auto 0;
  overflow: hidden;
}

.tab button {
  flex: 1;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  font-family: "Asap","Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  background-color: transparent;
  text-align: center;
  color: var(--tabview-header-color, #6C757D);
  font-weight: 900;
  border-bottom: 3px solid #e8e8e8;
  line-height: normal;
}

.tab button:hover {
  color: #19418BE5;
  border-bottom: 3px solid #19418BE5;
}

.tab button.active {
  color: #0436A0;
  border-bottom: 3px solid #0436A0;
}

.tab .tablinks {
  padding: 4px;
}

.tab-element {
  font-family: Asap, Roboto;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 8px 8px 8px 8px;
}

.tab-element p{
  position: relative;
  top: 1px;
  left: 4px;
  font-size: 13px;
}

@media (max-width: 600px) {
  .tab-element {
    padding: 9px 4px 9px 4px;
  }
}

@media (max-width: 375px) {
  .tab-element {
    padding: 9px 3px 9px 3px;
  }
  .tab-element p{
    font-size: 12.5px;
  }
}

@media (max-width: 350px) {
  .tab-element {
    padding: 9px 3px 9px 3px;
   }
 .tab-element p{
    font-size: 11px;
  }
  .tab .tablinks {
    font-size: 13px;
  }
}

@media (max-width: 320px) {
  .tab-element {
    padding: 9px 1px 9px 1px;
   }
 .tab-element p{
    font-size: 11px;
  }
  .tab .tablinks {
    font-size: 13px;
  }
}

@media (max-width: 290px) {
  .tab-element {
    padding: 2px 0.4px 2px 0.4px;
   }
 .tab-element p{
    font-size: 9px;
  }
  .tab .tablinks {
    font-size: 11px;
  }
}
