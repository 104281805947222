.form-list button {
    margin-top: -28px;
    margin-right: 10px;
    float: right;
}
.form-list-panel {
    box-shadow: 0 2px 20px 1px rgba(0, 0, 0, 0.2), 0 2px 41px 0 rgba(0, 0, 35, 0.14), 0 8px 3px 0 rgba(0, 0, 0, 0.12);
}

.button-header {
    position: relative;
    top: 15px;
    left: 15px;
}